<template>
  <div class="smev-setting-panel">
    <el-dialog
      :visible.sync="isCreateSmevSettingWindowVisible"
      width="80%"
      ref="createSmevSettingWindow"
      class="create-smev-setting-window"
      @close="closeCreateSmevSettingWindow"
      :close-on-click-modal="false">
      <span>
        <template v-if="smevSettingDto">
          <el-form :rules="createSmevSettingRules" :model="smevSettingDto" :disabled="isSmevSettingLoading" size="mini" label-width="200px" label-position="left">
            <el-form-item prop="name" :label="$locale.main.fields.name">
                <el-input v-model="smevSettingDto.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="value" :label="$locale.main.fields.value">
                <el-input v-model="smevSettingDto.value" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        </template>
      </span>
      <span slot="footer" class="dialog-footer">
        <span v-loading="true" v-if="isSmevSettingLoading"></span>
          <el-button icon="el-icon-close" :disabled="isSmevSettingLoading" size="small" @click="closeCreateSmevSettingWindow">{{$locale.main.button.cancel}}</el-button>
          <el-button icon="el-icon-success" :disabled="smevSettingDto == null || isSmevSettingLoading" size="small" @click="saveSmevSetting" type="primary">{{$locale.main.button.save}}</el-button>
      </span>
    </el-dialog>
    <el-container class="tools">
      <el-button icon="icon-plus-blue" @click="createSmevSetting"></el-button>
      <el-button icon="icon-edit-blue" @click="updateSmevSetting"></el-button>
      <el-button icon="icon-delete-blue" @click="deleteSmevSetting"></el-button>
    </el-container>
    <el-row class="main-table smev-setting-table">
      <el-col style="height: 100%">
        <el-table
          :indent="0"
          class="registry custom_scrollbar"
          height="100%"
          v-loading="loading"
          :data="smevSettings"
          stripe
          border
          ref="smev_settings_table"
          row-key="guid"
          current-row-key="guid"
          highlight-current-row
          @current-change="changeSmevSetting"
        >
        <el-table-column
          prop="id"
          :label="$locale.main.fields.id"
          width="60"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$locale.main.fields.name"
          width="220"
          header-align="center"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="value"
          :label="$locale.main.fields.value"
          header-align="center"
          align="left"
        ></el-table-column>
        </el-table>
        <el-footer style="height: 32px">
          <el-pagination
            class="smev-setting-pagination"
            :layout="'total'"
            :total="smevSettingsCount"
            @current-change="handleSmevSettingsPageChange"
          ></el-pagination>
        </el-footer>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SmevSettingCreateCommand from '@/services/AccessEditor/application/command/SmevSettingCreateCommand'
import SmevSettingUpdateCommand from '@/services/AccessEditor/application/command/SmevSettingUpdateCommand'
import SmevSettingDeleteCommand from '@/services/AccessEditor/application/command/SmevSettingDeleteCommand'
import SmevSettingsQuery from '@/services/AccessEditor/application/query/SmevSettingsQuery'
import SmevSettingByGuidQuery from '@/services/AccessEditor/application/query/SmevSettingByGuidQuery'
import SmevSetting, { SmevSettingDTO } from '@/services/AccessEditor/domain/model/Smev/SmevSetting'

export default {
  name: 'SmevSettingPanel',
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data () {
    return {
      loading: false,
      smevSettingsPageSize: 0,
      defaultSmevSettingsPageSize: 999999,
      smevSettingsCurrentPage: 0,
      smevSettingsPageLimit: 999999,
      smevSettingsCount: 0,
      allSmevSettingsCount: 0,
      smevSettings: [],
      smevSetting: null,
      smevSettingDtoPrev: null,
      smevSettingDto: null,
      informationPanelHeight: 0,
      isCreateSmevSettingWindowVisible: false,
      menu: [],
      dashboards: [],
      createSmevSettingRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }
    }
  },
  watch: {
    smevSettingCreated: function (location) {
      const guid = location.replace('/smev/settings/', '')
      this.loadSmevSettings(() => {
        this.closeCreateSmevSettingWindow()
        for (let i = 0; i < this.smevSettings.length; i += 1) {
          let el = this.smevSettings[i]
          if (el.guid == guid) {
            this.$refs.smev_settings_table.setCurrentRow(el)
            break
          }
        }
      })
    },
    isSmevSettingLoading: (state) => state,
    getSaveSmevSettingError: function (err) {

    },
    smevSettingDto: {
      handler: async function (curDto) {

      },
      deep: true
    }
  },
  computed: {
    isSmevSettingLoading () {
      return this.$store.getters['SmevSetting/isLoading']
    },
    smevSettingCreated () {
      return this.$store.getters['SmevSetting/getLocation']
    },
    getSaveSmevSettingError () {
      return this.$store.getters['SmevSetting/getError']
    }
  },
  methods: {
    async loadSmevSettings (callback) {
      await this.getQueryBus().execute(
        new SmevSettingsQuery({})
      ).then(data => {
        this.smevSettings = data
        this.smevSettingsCount = data[0].count
        if (typeof callback === 'function') {
          callback()
        }
      })
    },
    createSmevSetting () {
      this.smevSettingDtoPrev = this.smevSettingDto
      this.smevSettingDto = new SmevSettingDTO({})
      this.isCreateSmevSettingWindowVisible = true
    },
    async updateSmevSetting () {
      if (this.smevSetting !== null) {
        this.isCreateSmevSettingWindowVisible = true
      } else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        })
      }
    },
    saveSmevSetting () {
      let smevSetting = SmevSetting.create(this.smevSettingDto)
      if (smevSetting.getId() == null) {
        this.getCommandBus().execute(
          new SmevSettingCreateCommand(
            smevSetting.getName(),
            smevSetting.getValue()
          )
        )
      } else {
        this.getCommandBus().execute(
          new SmevSettingUpdateCommand(
            smevSetting.getGuid(),
            smevSetting.getName(),
            smevSetting.getValue()
          )
        ).then(async () => {
          await this.getQueryBus().execute(
            new SmevSettingByGuidQuery(smevSetting.getGuid())
          ).then(data => {
            this.smevSettingDtoPrev = new SmevSettingDTO(data)
            this.smevSettingDto = this.smevSettingDtoPrev
            for (let i = 0; i < this.smevSettings.length; i += 1) {
              if (this.smevSettings[i].guid == this.smevSettingDto.guid) {
                this.smevSettings[i] = this.smevSettingDto
                break
              }
            }
          })
          this.closeCreateSmevSettingWindow()
        })
      }
    },
    closeCreateSmevSettingWindow () {
      this.isCreateSmevSettingWindowVisible = false
      if (this.smevSettingDtoPrev !== null) {
        this.smevSettingDto = this.smevSettingDtoPrev
        this.smevSetting = SmevSetting.create(this.smevSettingDto)
      }
    },
    changeSmevSetting (smevSettingDto) {
      if (smevSettingDto !== null) {
        this.smevSetting = SmevSetting.create(smevSettingDto)
        this.smevSettingDto = smevSettingDto
      }
    },
    deleteSmevSetting () {
      if (this.smevSetting == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        })
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          this.getCommandBus().execute(
            new SmevSettingDeleteCommand(
              this.smevSetting.getGuid()
            )
          ).then((response) => {
            this.smevSetting = null
            this.$refs.smev_settings_table.setCurrentRow(null)
            this.loadSmevSettings()
          })
        }).catch((error) => { console.log(error) })
      }
    },
    handleSmevSettingsPageChange (val) {
      val--
      this.smevSettingsCurrentPage = (val * this.smevSettingsPageLimit)
      this.loadSmevSettings()
    }
  },
  mounted () {
    this.loadSmevSettings()
  }
}
</script>
