
import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class SmevSettingDTO {
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    value?: Nullable<string>;

    constructor ({ id, guid, name, value }: {id?: Nullable<number>, guid?: Nullable<string>, name?: Nullable<string>, value?: Nullable<string>}) {
      this.id = id
      this.guid = guid
      this.name = name
      this.value = value
    }
}

export default class SmevSetting extends AbstractBaseEntity {
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private value?: Nullable<string>;

    constructor (
      id: Nullable<number>,
      guid: Nullable<string>,
      name: string,
      value: Nullable<string>
    ) {
      super()
      this.id = id
      this.guid = guid
      this.name = name
      this.value = value
    }

    static create (dto: SmevSettingDTO): SmevSetting {
      return new SmevSetting(
        dto.id,
        dto.guid,
        dto.name,
        dto.value
      )
    }

    setId (id: number): void {
      this.id = id
    }

    getId (): number {
      return this.id
    }

    setGuid (guid: string): void {
      this.guid = guid
    }

    getGuid (): string {
      return this.guid
    }

    setName (name: string): void {
      this.name = name
    }

    getName (): string {
      return this.name
    }

    setValue (value: string): void {
      this.value = value
    }

    getValue (): string {
      return this.value
    }
}
